import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/layout';
import Head from 'components/layout/head';
import { Row, Column } from 'components/grid';
import Cat from 'components/cat/index';

const notFoundPage: React.FC = () =>
  typeof window !== 'undefined' && window ? (
    <Layout type="error">
      <Head pageTitle="Ups, something went wrong" />

      <Row tag="header">
        <Column>
          <div className="spacer" />
          <h1 className="text-center">
            <code className="text-secondary">(╯°□°)╯︵ ┻━┻</code>
          </h1>
          <p className="lead text-light text-center">
            You just broke a perfectly crafted website without any regards to
            its feelings.
          </p>
          <p className="lead text-light text-center">
            You better <Link to="/">head back</Link> right now!
          </p>
          <Cat />
        </Column>
      </Row>
    </Layout>
  ) : null;

export default notFoundPage;
